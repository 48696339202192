// 表格头部信息
<template>
  <div class="headeritle">
    <div>待确认文件{{count}}</div>
    <div>
      <base-button label='批量下载' @click="downAll(fileList)"></base-button>
      <base-button label='文件信息' v-if="active" @click="showFileInfo"></base-button>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { BaseButton },
  props: {
    count: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: null
    }
  },
  methods: {
    // 批量下载
    downAll () {
      console.log(this.fileList, '文件id')
    },
    // 显示文件序
    showFileInfo () {

    }
  }
}
</script>
<style lang="scss" scoped>
.headeritle{
  display: flex;
  justify-content: space-between;
}
</style>
